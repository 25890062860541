@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@500&family=Josefin+Sans:wght@400;500;600;700&display=swap");

:root {
  --color-dark: #101010;
  --color-light: #fff;
  --color-accent: #7cedf4;
  --container-width-lg: 75%;
  --container-width-md: 85%;
  --container-width-ms: 90%;
  --icon-size: 25px;
  --gradient-text: linear-gradient(
    120deg,
    #1c99fe 20.69%,
    #7644ff 50.19%,
    var(--color-accent) 79.69%
  );
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-light);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-accent);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  background-color: var(--color-dark);
  font-family: "Josefin Sans", sans-serif;
  color: var(--color-light);
  overflow-x: hidden;
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
  left: 0;
  right: 0;
}

.bg {
  background-color: #101010;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: color-dodge;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.white-btn {
  background-color: var(--color-accent);
  color: var(--color-dark);
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  font-size: 0.9em;
  font-weight: 800;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid var(--color-accent);
  cursor: pointer;
}

.white-btn:hover {
  background-color: white;
  color: var(--color-dark);
  border: 1px solid var(--color-accent);
  transition: 500ms ease-in-out;
}

.accent-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
  gap: 5px;
  font-weight: 800;
  padding: 12px;
  border-radius: 10px;
  background-color: white;
  color: var(--color-dark);
  border: 1px solid white;
  cursor: pointer;
}

.accent-btn:hover {
  background-color: var(--color-accent);
  color: var(--color-dark);
  transition: 500ms ease-in-out;
  border: 1px solid white;
}

.subtitle {
  display: inline-block;
  font-weight: 650;
  letter-spacing: 0.5px;
  color: var(--color-accent);
}

/* @media only screen and (max-width : 1024px) {

    .container {
        width: var(--container-width-md);
    }

    .bg {
        background-color: rgba(0, 0, 0, 0.4);

    }
} */

@media only screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-ms);
  }

  .bg {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
