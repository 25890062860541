.footer_container {
  background-color: var(--color-dark);
  color: white;
  padding: 20px;
  text-align: center;
}

.footer_title {
  margin: 0;
  font-size: 2rem;
}

.footer_subtitle {
  margin: 10px 0;
  font-size: 1.2rem;
}

.social-icons a {
  color: var(--color-accent);
  margin: 0 10px;
  text-decoration: none;
}

.social-icons a:hover {
  color: white;
}

.footer_links {
  margin: 20px 0;
}

.footer_link {
  color: var(--color-accent);
  margin: 0 10px;
  text-decoration: none;
}

.footer_link:hover {
  color: white;
}

p {
  margin: 20px 0 0;
}
