.projects_container {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  height: auto;
  justify-content: center;
  align-items: center;
}

.projects_container h2 {
  color: var(--color-accent);
  align-self: flex-start;
}

/* Projects Compo */

.individual-project-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: safe;
  margin-top: 20px;
  gap: 2rem;
}

/* img {
    height: fit-content;
    width: fit-content;
    padding: auto;
    margin: auto;
    cursor: pointer;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: var(--color-dark);
    border: 0.01px solid var(--color-accent);
    border-radius: 10px;
    box-shadow: ;
} */

.project-img {
  width: 15vw;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 20px 0px rgba(0, 255, 255, 0.1);
}

#project-desc {
  color: white;
  margin: 40px 10px;
  text-align: justify;
}

#project-title {
  color: var(--color-accent);
  font-size: 2rem;
  margin: 40px 10px;
}

.btns {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 40px 10px;
}

/* //tabs */

#project-tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4rem 0.8rem;
  text-align: justify;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 50px;
}

#project-tabs .tab {
  padding: 2px 6px;
  font-weight: 600;
  padding-top: 4.5px;
  border-radius: 20px;
  color: var(--color-light);
  text-decoration: none;
  cursor: pointer;
}

#project-tabs .tab:hover {
  background-color: var(--color-accent);
  color: white;
  transition: 500ms ease-in-out;
}

.clicked {
  background-color: var(--color-accent);
  color: white;
  transition: 500ms ease-in-out;
}
