.skills_container {
  display: flex;
  flex-direction: column;
}

.skills_container h2 {
  color: var(--color-accent);
}

.skill-cards-container {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 50px;
  gap: 1.5rem;
}

.skill-card {
  background-color: var(--color-dark);
  border-radius: 10px;
  width: 33.333%;
  display: flex;
  padding: 15px;
  flex-direction: column;
  cursor: pointer;
}

.skill-card:hover {
  box-shadow: 0px 2px 40px 0px rgba(0, 255, 255, 0.1);
  transform: translateY(-5px);
  transition: 500ms ease-in-out;
}

.skill-card .skill-img {
  width: 100%;
  height: 150px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin-bottom: 15px;
}

.skill-card .web-dev-img {
  background-image: url(../../assets/service-3.jpeg);
}

.skill-card .app-dev-img {
  background-image: url(../../assets/service-1.jpeg);
}

.skill-card .flutter-dev-img {
  background-image: url(../../assets/service-2.png);
  background-color: rgba(0, 0, 0, 0.815);
}

.skill-card h3 {
  margin-bottom: 15px;
}

.skill-card p {
  line-height: 1.2;
  margin-bottom: 20px;
  color: rgb(230, 230, 230);
}

@media only screen and (max-width: 1024px) {
  .skill-cards-container {
    display: flex;
    flex-direction: column;
  }

  .skill-card {
    width: auto;
  }

  .skills_container h2 {
    text-align: center;
  }
}
