.privacy-policy-container h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-accent);
}

.privacy-policy-container a {
  color: var(--color-accent);
}

.privacy-policy-container ul {
  list-style-type: circle;
  margin-left: 20px;
}

.privacy-policy-container li {
  margin: 10px 0;
  list-style-type: circle;
}
