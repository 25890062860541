.topbar_container {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  height: 60px;
  z-index: 100000;
}

.nav-colored {
  height: 55px;
  width: 100%;
  z-index: 10000;
  position: fixed;
  background-color: var(--color-dark);
  transition: 200ms ease-in;
  color: var(--color-dark);
  box-shadow: 1px 1px 1px 1px var(--color-accent);
}

.nav-link.clicked {
  color: var(--color-dark);
}
.topbar_container h3 {
  color: var(--color-accent);
  margin: 15px 0;
  font-size: 2em;
  font-weight: 300;
  text-shadow: 1px 0.5px var(--color-dark);
  font-family: "sans-serif", sans-serif;
  cursor: pointer;
}

.topbar_container h3:hover {
  transition: 1000ms ease-in-out;
  transform: rotate3d(0, 1, 0, 360deg);
}

.topbar_container nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: 15px 0;
  text-decoration: none;
}

.topbar_container nav a {
  padding: 4px 12px;
  font-weight: 600;
  padding-top: 4.5px;
  border-radius: 20px;
  color: var(--color-light);
  font-size: 16px;
  text-decoration: none;
}

.topbar_container nav a:hover {
  background-color: var(--color-accent);
  color: var(--color-dark);
  transition: 500ms ease-in-out;
}

/* //Menu Button for small devices */
.nav-btn {
  background-color: transparent;
  color: var(--color-accent);
  cursor: pointer;
  margin: 15px 0;
  display: none;
}

@media only screen and (max-width: 1024px) {
  .nav-btn {
    display: block;
  }

  .topbar_container nav {
    background-color: var(--color-dark);
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    margin: 10px 15px;
    padding: 15px;
    padding-top: 45px;
    right: 0;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
    border-bottom: 5px solid var(--color-accent);
    transition: 500ms ease-in-out;
    transform: translateY(-100vh);
    justify-content: center;
  }

  .topbar_container .responsive_nav {
    transform: none;
  }
}
