.about_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: justify;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.about-dp {
  background-image: url(../../assets/bg-dark-about.png);
  height: 22vw;
  width: 22vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: auto;
  background-blend-mode: color-dodge;
}

.about-txt {
  width: 45vw;
  font-size: 1.2em;
}

.about-txt h2 {
  color: var(--color-accent);
}

/* Media Query */

@media only screen and (max-width: 1024px) {
  .about_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about-txt h2 {
    text-align: center;
    margin-top: 50px;
  }

  .about-txt {
    margin: auto;
    width: 100%;
  }

  .about-dp {
    width: 50vw;
    height: 50vw;
    margin: auto;
  }
}
