@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@500&family=Josefin+Sans:wght@400;500;600;700&display=swap);
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  outline: 0;
  border: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --color-dark: #101010;
  --color-light: #fff;
  --color-accent: #7cedf4;
  --container-width-lg: 75%;
  --container-width-md: 85%;
  --container-width-ms: 90%;
  --icon-size: 25px;
  --gradient-text: linear-gradient(
    120deg,
    #1c99fe 20.69%,
    #7644ff 50.19%,
    var(--color-accent) 79.69%
  );
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  background: var(--color-light);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7cedf4;
  background: var(--color-accent);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  background-color: #101010;
  background-color: var(--color-dark);
  font-family: "Josefin Sans", sans-serif;
  color: #fff;
  color: var(--color-light);
  overflow-x: hidden;
}

.container {
  width: 75%;
  width: var(--container-width-lg);
  margin: 0 auto;
  left: 0;
  right: 0;
}

.bg {
  background-color: #101010;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: color-dodge;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.white-btn {
  background-color: #7cedf4;
  background-color: var(--color-accent);
  color: #101010;
  color: var(--color-dark);
  display: flex;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  font-size: 0.9em;
  font-weight: 800;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #7cedf4;
  border: 1px solid var(--color-accent);
  cursor: pointer;
}

.white-btn:hover {
  background-color: white;
  color: #101010;
  color: var(--color-dark);
  border: 1px solid #7cedf4;
  border: 1px solid var(--color-accent);
  transition: 500ms ease-in-out;
}

.accent-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
  grid-gap: 5px;
  gap: 5px;
  font-weight: 800;
  padding: 12px;
  border-radius: 10px;
  background-color: white;
  color: #101010;
  color: var(--color-dark);
  border: 1px solid white;
  cursor: pointer;
}

.accent-btn:hover {
  background-color: #7cedf4;
  background-color: var(--color-accent);
  color: #101010;
  color: var(--color-dark);
  transition: 500ms ease-in-out;
  border: 1px solid white;
}

.subtitle {
  display: inline-block;
  font-weight: 650;
  letter-spacing: 0.5px;
  color: #7cedf4;
  color: var(--color-accent);
}

/* @media only screen and (max-width : 1024px) {

    .container {
        width: var(--container-width-md);
    }

    .bg {
        background-color: rgba(0, 0, 0, 0.4);

    }
} */

@media only screen and (max-width: 1024px) {
  .container {
    width: 90%;
    width: var(--container-width-ms);
  }

  .bg {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.topbar_container {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  height: 60px;
  z-index: 100000;
}

.nav-colored {
  height: 55px;
  width: 100%;
  z-index: 10000;
  position: fixed;
  background-color: var(--color-dark);
  transition: 200ms ease-in;
  color: var(--color-dark);
  box-shadow: 1px 1px 1px 1px var(--color-accent);
}

.nav-link.clicked {
  color: var(--color-dark);
}
.topbar_container h3 {
  color: var(--color-accent);
  margin: 15px 0;
  font-size: 2em;
  font-weight: 300;
  text-shadow: 1px 0.5px var(--color-dark);
  font-family: "sans-serif", sans-serif;
  cursor: pointer;
}

.topbar_container h3:hover {
  transition: 1000ms ease-in-out;
  -webkit-transform: rotate3d(0, 1, 0, 360deg);
          transform: rotate3d(0, 1, 0, 360deg);
}

.topbar_container nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
  margin: 15px 0;
  text-decoration: none;
}

.topbar_container nav a {
  padding: 4px 12px;
  font-weight: 600;
  padding-top: 4.5px;
  border-radius: 20px;
  color: var(--color-light);
  font-size: 16px;
  text-decoration: none;
}

.topbar_container nav a:hover {
  background-color: var(--color-accent);
  color: var(--color-dark);
  transition: 500ms ease-in-out;
}

/* //Menu Button for small devices */
.nav-btn {
  background-color: transparent;
  color: var(--color-accent);
  cursor: pointer;
  margin: 15px 0;
  display: none;
}

@media only screen and (max-width: 1024px) {
  .nav-btn {
    display: block;
  }

  .topbar_container nav {
    background-color: var(--color-dark);
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    margin: 10px 15px;
    padding: 15px;
    padding-top: 45px;
    right: 0;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
    border-bottom: 5px solid var(--color-accent);
    transition: 500ms ease-in-out;
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
    justify-content: center;
  }

  .topbar_container .responsive_nav {
    -webkit-transform: none;
            transform: none;
  }
}

button {
  padding: 0;
  margin: 0;
}

.header_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: relative;
  border-radius: 20px;
  height: 100vh;
}

.header_container .intro {
  text-align: start;
  line-height: 70px;
}

.header_container .intro-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header_container .intro-wrapper .social-icons {
  display: flex;
  flex-direction: column;
  grid-gap: 1.2rem;
  gap: 1.2rem;
  margin-right: 100px;
}

.header_container .intro-wrapper .social-icons a:hover {
  transition: 200ms ease-out;
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}

.header_container .intro-wrapper .social-icons a {
  color: var(--color-accent);
}

.header_container .intro-wrapper .social-icons span {
  height: 15px;
  width: 2px;
  align-self: center;
  background-color: var(--color-accent);
  border-radius: 5px;
}

#typer {
  font-size: 46px;
  line-height: 1.2;
}

.header_container .dp {
  height: 300px;
  width: 300px;
  cursor: pointer;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/static/media/header_dp.9cece1a5.png);
  /* background-color: var(--color-dark); */
  /* border: 2px solid var(--color-accent); */
  border-radius: 10000px;
}

.header_container .intro h3 {
  font-weight: 100;
  font-style: bold;
  font-size: 1.4em;
  color: grey;
}

.header_container .intro h1 {
  font-size: 4em;
  margin-bottom: 20px;
  padding: 0;
}

.header_container .intro h1 .accent-text {
  color: var(--color-accent);
}

.header_container .intro .btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 15px 10px;
}

@media only screen and (max-width: 1024px) {
  .header_container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  #typer {
    font-size: 36px;
    line-height: 1.2;
  }

  .header_container .intro {
    text-align: center;
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 50px;
  }

  .header_container .intro .btns {
    display: flex;
    flex-direction: column;
    max-width: 150px;
    grid-gap: 1.2rem;
    gap: 1.2rem;
  }

  .header_container .dp {
    width: 40vw;
    height: 40vw;
  }

  .header_container .intro h3 {
    font-size: 1.4em;
  }

  .header_container .intro h1 {
    font-size: 3em;
  }

  .header_container .intro-wrapper .social-icons {
    display: none;
  }
}

@media only screen and (max-width: 700) {
  .header_container {
    margin-top: 80px;
  }
  #typer {
    font-size: 28px;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 450) {
  .header_container {
    margin-top: 80px;
  }
  #typer {
    font-size: 28px;
    line-height: 1.2;
  }

  .header_container .dp {
    display: none;
  }
}

.about_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: justify;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.about-dp {
  background-image: url(/static/media/bg-dark-about.5e317c99.png);
  height: 22vw;
  width: 22vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: auto;
  background-blend-mode: color-dodge;
}

.about-txt {
  width: 45vw;
  font-size: 1.2em;
}

.about-txt h2 {
  color: var(--color-accent);
}

/* Media Query */

@media only screen and (max-width: 1024px) {
  .about_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about-txt h2 {
    text-align: center;
    margin-top: 50px;
  }

  .about-txt {
    margin: auto;
    width: 100%;
  }

  .about-dp {
    width: 50vw;
    height: 50vw;
    margin: auto;
  }
}

.skills_container {
  display: flex;
  flex-direction: column;
}

.skills_container h2 {
  color: var(--color-accent);
}

.skill-cards-container {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 50px;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.skill-card {
  background-color: var(--color-dark);
  border-radius: 10px;
  width: 33.333%;
  display: flex;
  padding: 15px;
  flex-direction: column;
  cursor: pointer;
}

.skill-card:hover {
  box-shadow: 0px 2px 40px 0px rgba(0, 255, 255, 0.1);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  transition: 500ms ease-in-out;
}

.skill-card .skill-img {
  width: 100%;
  height: 150px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin-bottom: 15px;
}

.skill-card .web-dev-img {
  background-image: url(/static/media/service-3.892dad2e.jpeg);
}

.skill-card .app-dev-img {
  background-image: url(/static/media/service-1.504b9cec.jpeg);
}

.skill-card .flutter-dev-img {
  background-image: url(/static/media/service-2.1f0b85ea.png);
  background-color: rgba(0, 0, 0, 0.815);
}

.skill-card h3 {
  margin-bottom: 15px;
}

.skill-card p {
  line-height: 1.2;
  margin-bottom: 20px;
  color: rgb(230, 230, 230);
}

@media only screen and (max-width: 1024px) {
  .skill-cards-container {
    display: flex;
    flex-direction: column;
  }

  .skill-card {
    width: auto;
  }

  .skills_container h2 {
    text-align: center;
  }
}

.contact_container {
    margin-bottom: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contact-form {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}

.contact-form h2 {
    color: var(--color-accent);
}

form div {
    display: flex;
    flex-direction: column;

}

form button {
    margin-top: 25px;
}

form div .form-control {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.2em;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
    text-decoration: none;
    box-shadow: 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
    padding: 5px 10px;
    padding-top: 10px;
    width: 30vw;
    color: var(--color-light);
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid grey;
}

form div .form-control:focus {
    border: 1px solid var(--color-accent);
}

form div .form-control:hover {
    border: 1px solid var(--color-accent);
}

.contact_container .contact-img {
    background-image: url(/static/media/contact.84d38b77.png);
    width: 30vw;
    height: 30vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 25px;
}

@media only screen and (max-width : 1024px) {
    .contact_container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .contact-form {
        width: 90%;
        display: flex;
        flex-direction: column;
        grid-gap: 1.5rem;
        gap: 1.5rem;
    }

    form div .form-control {
        width: 100%;
    }

    .contact_container .contact-img {
        width: 100%;
    }

    .contact-form h2 {
        text-align: center;

    }
}
.projects_container {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  flex-direction: column;
  height: auto;
  justify-content: center;
  align-items: center;
}

.projects_container h2 {
  color: var(--color-accent);
  align-self: flex-start;
}

/* Projects Compo */

.individual-project-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: safe;
  margin-top: 20px;
  grid-gap: 2rem;
  gap: 2rem;
}

/* img {
    height: fit-content;
    width: fit-content;
    padding: auto;
    margin: auto;
    cursor: pointer;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: var(--color-dark);
    border: 0.01px solid var(--color-accent);
    border-radius: 10px;
    box-shadow: ;
} */

.project-img {
  width: 15vw;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 20px 0px rgba(0, 255, 255, 0.1);
}

#project-desc {
  color: white;
  margin: 40px 10px;
  text-align: justify;
}

#project-title {
  color: var(--color-accent);
  font-size: 2rem;
  margin: 40px 10px;
}

.btns {
  display: flex;
  flex-direction: row;
  grid-gap: 2rem;
  gap: 2rem;
  margin: 40px 10px;
}

/* //tabs */

#project-tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 0.4rem 0.8rem;
  gap: 0.4rem 0.8rem;
  text-align: justify;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 50px;
}

#project-tabs .tab {
  padding: 2px 6px;
  font-weight: 600;
  padding-top: 4.5px;
  border-radius: 20px;
  color: var(--color-light);
  text-decoration: none;
  cursor: pointer;
}

#project-tabs .tab:hover {
  background-color: var(--color-accent);
  color: white;
  transition: 500ms ease-in-out;
}

.clicked {
  background-color: var(--color-accent);
  color: white;
  transition: 500ms ease-in-out;
}

.footer_container {
  background-color: var(--color-dark);
  color: white;
  padding: 20px;
  text-align: center;
}

.footer_title {
  margin: 0;
  font-size: 2rem;
}

.footer_subtitle {
  margin: 10px 0;
  font-size: 1.2rem;
}

.social-icons a {
  color: var(--color-accent);
  margin: 0 10px;
  text-decoration: none;
}

.social-icons a:hover {
  color: white;
}

.footer_links {
  margin: 20px 0;
}

.footer_link {
  color: var(--color-accent);
  margin: 0 10px;
  text-decoration: none;
}

.footer_link:hover {
  color: white;
}

p {
  margin: 20px 0 0;
}

.terms-container h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-accent);
}

.terms-container a {
  color: var(--color-accent);
}

.privacy-policy-container h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-accent);
}

.privacy-policy-container a {
  color: var(--color-accent);
}

.privacy-policy-container ul {
  list-style-type: circle;
  margin-left: 20px;
}

.privacy-policy-container li {
  margin: 10px 0;
  list-style-type: circle;
}

