.contact_container {
    margin-bottom: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contact-form {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.contact-form h2 {
    color: var(--color-accent);
}

form div {
    display: flex;
    flex-direction: column;

}

form button {
    margin-top: 25px;
}

form div .form-control {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.2em;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
    text-decoration: none;
    box-shadow: 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
    padding: 5px 10px;
    padding-top: 10px;
    width: 30vw;
    color: var(--color-light);
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid grey;
}

form div .form-control:focus {
    border: 1px solid var(--color-accent);
}

form div .form-control:hover {
    border: 1px solid var(--color-accent);
}

.contact_container .contact-img {
    background-image: url(../../assets/contact.png);
    width: 30vw;
    height: 30vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 25px;
}

@media only screen and (max-width : 1024px) {
    .contact_container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .contact-form {
        width: 90%;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    form div .form-control {
        width: 100%;
    }

    .contact_container .contact-img {
        width: 100%;
    }

    .contact-form h2 {
        text-align: center;

    }
}