button {
  padding: 0;
  margin: 0;
}

.header_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: relative;
  border-radius: 20px;
  height: 100vh;
}

.header_container .intro {
  text-align: start;
  line-height: 70px;
}

.header_container .intro-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header_container .intro-wrapper .social-icons {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin-right: 100px;
}

.header_container .intro-wrapper .social-icons a:hover {
  transition: 200ms ease-out;
  transform: scale(1.15);
}

.header_container .intro-wrapper .social-icons a {
  color: var(--color-accent);
}

.header_container .intro-wrapper .social-icons span {
  height: 15px;
  width: 2px;
  align-self: center;
  background-color: var(--color-accent);
  border-radius: 5px;
}

#typer {
  font-size: 46px;
  line-height: 1.2;
}

.header_container .dp {
  height: 300px;
  width: 300px;
  cursor: pointer;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("./../../assets/header_dp.png");
  /* background-color: var(--color-dark); */
  /* border: 2px solid var(--color-accent); */
  border-radius: 10000px;
}

.header_container .intro h3 {
  font-weight: 100;
  font-style: bold;
  font-size: 1.4em;
  color: grey;
}

.header_container .intro h1 {
  font-size: 4em;
  margin-bottom: 20px;
  padding: 0;
}

.header_container .intro h1 .accent-text {
  color: var(--color-accent);
}

.header_container .intro .btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin: 15px 10px;
}

@media only screen and (max-width: 1024px) {
  .header_container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  #typer {
    font-size: 36px;
    line-height: 1.2;
  }

  .header_container .intro {
    text-align: center;
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 50px;
  }

  .header_container .intro .btns {
    display: flex;
    flex-direction: column;
    max-width: 150px;
    gap: 1.2rem;
  }

  .header_container .dp {
    width: 40vw;
    height: 40vw;
  }

  .header_container .intro h3 {
    font-size: 1.4em;
  }

  .header_container .intro h1 {
    font-size: 3em;
  }

  .header_container .intro-wrapper .social-icons {
    display: none;
  }
}

@media only screen and (max-width: 700) {
  .header_container {
    margin-top: 80px;
  }
  #typer {
    font-size: 28px;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 450) {
  .header_container {
    margin-top: 80px;
  }
  #typer {
    font-size: 28px;
    line-height: 1.2;
  }

  .header_container .dp {
    display: none;
  }
}
